/* eslint-disable */
type Colors = 'white'
| 'gray'
| 'borderColor'
| 'darkGray'
| 'textColor'
| 'orange'
| 'orangeHover'
| 'purple'
| 'purpleHover'
| 'bgBlue'
| 'black'
| 'bgGray'
| 'bgPink'
| 'textGray'
| 'textDarkGray';

export const colors: { [key in Colors ]: string } = {
  white: '#fff',
  gray: '#ececec',
  borderColor: '#dedede',
  darkGray: '#2b2b2b',
  textColor: '#2b2b2b',
  orange: '#e99413',
  orangeHover: '#FFA552',
  purple: '#5a3087',
  purpleHover: '#4d2777',
  bgBlue: '#f3fbfd',
  black: '#000',
  bgGray: '#f8f8f8',
  bgPink: '#fff2ec',
  textGray: '#747474',
  textDarkGray: '#333333',
};

// global breakpoints
const BP0 = 400;
const BP1 = 768;
const BP2 = 995;
const XS_MAX = BP0 - 1;
const SM_MAX = BP1 - 1;
const MD_MIN = BP1;
const MD_MAX = BP2 - 1;
const LG_MIN = BP2;
const LAYOUTBP = 1360;
const MAPBP = 1080;

// media query strings
const xsOnly = `max-width: ${XS_MAX}px`;
const smOnly = `max-width: ${SM_MAX}px`;
const mdMin = `min-width: ${MD_MIN}px`;
const mdMax = `max-width: ${MD_MAX}px`;
const lgOnly = `min-width: ${LG_MIN}px`;
const layoutBPUp = `min-width: ${LAYOUTBP}px`;
const MapBPDown = `max-width: ${MAPBP}px`;

type Breakpoints = 'xsOnly' | 'smOnly' | 'mdOnly' | 'mdDown' | 'mdUp' | 'lgOnly' | 'layoutBPUp' | 'MapBPDown';

export const mediaQueries: { [key in Breakpoints]: string } = {
  xsOnly: `@media (${xsOnly})`,
  smOnly: `@media (${smOnly})`,
  mdOnly: `@media (${mdMin}) and (${mdMax})`,
  mdDown: `@media (${mdMax})`,
  mdUp: `@media (${mdMin})`,
  lgOnly: `@media (${lgOnly})`,
  layoutBPUp: `@media (${layoutBPUp})`,
  MapBPDown: `@media (${MapBPDown})`,
};
