import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  ResponsiveBreak__sm: {
    display: 'none',
    [mq.smOnly]: {
      display: 'initial',
    },
  },
  ResponsiveBreak__md: {
    display: 'none',
    [mq.mdDown]: {
      display: 'initial',
    },
  },
  ResponsiveBreak__lg: {
    display: 'none',
    [mq.lgOnly]: {
      display: 'initial',
    },
  },
}));

type ResponsiveBreakProps = {
  size: 'sm' | 'md' | 'lg';
};

const ResponsiveBreak: React.FC<ResponsiveBreakProps> = ({ size }) => {
  const classes = useStyles();
  switch (size) {
    case 'sm': {
      return <br className={classes.ResponsiveBreak__sm} />;
    }
    case 'md': {
      return <br className={classes.ResponsiveBreak__md} />;
    }
    case 'lg': {
      return <br className={classes.ResponsiveBreak__lg} />;
    }
    default: {
      return null;
    }
  }
};

export default ResponsiveBreak;
