import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  PageTopButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9000,
    border: 'none',
    borderRadius: '50%',
    color: colors.white,
    height: 40,
    width: 40,
    position: 'fixed',
    right: 24,
    opacity: 0,
    transition: 'opacity .5s ease',
    marginBottom: 15,
    [mq.smOnly]: {
      bottom: 60,
    },
    [mq.mdUp]: {
      bottom: 96,
    },
  },
  black: {
    backgroundColor: 'black',
  },
  purple: {
    backgroundColor: `${colors.purple}`,
  },
  shouldShow: {
    opacity: 1,
  },
}));

type PageTopButtonProps = {
  color?: string
};

const PageTopButton: React.FC<PageTopButtonProps> = ({ color }) => {
  const [shouldShow, setShouldShow] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const scrollCb = () => {
      if (window.scrollY >= 200) {
        setShouldShow(true);
      } else {
        setShouldShow(false);
      }
    };
    window.addEventListener('scroll', scrollCb);
    return () => window.removeEventListener('scroll', scrollCb);
  }, []);

  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <button className={clsx(classes.PageTopButton, shouldShow && classes.shouldShow, color === 'black' && classes.black, color !== 'black' && classes.purple)} type="button" onClick={scrollToTop}>
      <KeyboardArrowUpIcon />
    </button>
  );
};

PageTopButton.defaultProps = {
  color: colors.purple,
};

export default React.memo(PageTopButton);
